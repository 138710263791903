<template>
    <div>
        <!-- Breadcrumbs -->
        <v-container v-if="smAndUp">
            <v-row>
                <v-col>
                    <v-breadcrumbs
                        :items="breadCrumbs"
                        divider=">"
                    ></v-breadcrumbs>
                </v-col>
            </v-row>
        </v-container>

        <!-- お客様の声 -->
        <v-container>
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <h1><heading>40代・A.Mさん</heading></h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" :class="smAndUp ? null : 'order-2'">
                    <v-img :src="imageGuest1" contain> </v-img>
                </v-col>
                <v-col cols="12" md="6" class="align-self-center">
                    <div
                        :class="smAndUp ? 'text-h4' : 'text-h6'"
                        class="font-weight-thin"
                        v-html="smAndUp ? leadPc : leadSp"
                    ></div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" class="order-2">
                    <v-img :src="imageGuest2" contain> </v-img>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="font-weight-thin text-body-1">
                        <div class="question mb-4">
                            Q1:
                            指導を受けようと思ったきっかけはなんですか？(体験しようとしたきっかけ）
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            女性らしい体を作りたくて。それと程よく筋肉をつけたいとも思ったので。REVOISTで指導を受けることがその近道だと思いました。
                        </div>
                        <div class="question my-4">
                            Q2:
                            指導を受ける前の体はどんな状態でしたか？どんな生活でしたか？
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            筋肉量が今より少ないのに、好きなものを好きなだけ食べる食生活でした。
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" :class="smAndUp ? null : 'order-2'">
                    <v-img :src="imageGuest3" contain> </v-img>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="font-weight-thin text-body-1">
                        <div class="question mb-4">
                            Q3:
                            結果が出た後（体が変わった後）見た目や生活はどう変わりましたか？
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            カラダのために良いものを意識して食べる食生活に変わりました。
                        </div>
                        <div class="question my-4">
                            Q4:
                            REVOISTの指導を受けたほうがいい人（オススメしたいひと）、逆に受けないほうが良い・必要ない人はどんなひとでしょうか？
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            今まで筋トレの指導を受けたことがない40代前後の女性の方には絶対オススメしたいです。
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" class="order-2">
                    <v-img :src="imageGuest4" contain> </v-img>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="font-weight-thin text-body-1">
                        <div class="question mb-4">
                            Q5:
                            指導を受けていなかったらどうなっていたと思いますか？
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            体型はメリハリがなく、食生活も意識せず。きっと今の体型とはほど遠いカラダ担っていたと思います。
                        </div>
                        <div class="question my-4">
                            Q6:
                            指導を受けようか迷っている人へメッセージをお願いします。
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            見た目が確実に変わるので、絶対にオススメしたいです。
                            ただ痩せただけのカラダは、40代では避けられない皮膚のたるみが必ず残ってしまい、それは美しい健康的なカラダではないです‥。
                            皮下脂肪が落ちたその下に筋肉があるカラダは引き締まっていて本当に綺麗なんです。
                            私は大会に出た時、減量して、それはものすごく実感しています。
                            だから、ただ細いというカラダじゃなくて、引き締まったカラダを目指し欲しいです。
                            けして、私はムキムキな筋肉を目指している訳ではなく女性らしい程よい筋肉をつけたい・維持したいとの思いでトレーニング続けています。
                            やれば必ず筋力も上がり、カラダは応えてくれるので、自然と食事への意識も高まります。
                            40代…まだまだ輝けます！
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col md="8">
                    <link-tag compName="Testimonial"
                        >お客様の声一覧に戻る</link-tag
                    >
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </div>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import LinkTag from '@/components/LinkTag.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
    },
    components: {
        Heading,
        FooterContents,
        LinkTag,
    },

    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: 'お客様の声',
                    disabled: false,
                    href: 'testimonial',
                },
                {
                    text: '20代・A.Yさん',
                    disabled: false,
                    href: '',
                },
            ]
        },

        imageGuest1() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial–3/4B7A9028.png'
                : 'static/sp_testimonial–3/testimonial01_img01.png'
        },
        imageGuest2() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial–3/グループ 17.png'
                : 'static/sp_testimonial–3/testimonial01_img02.png'
        },
        imageGuest3() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial–3/グループ 58.png'
                : 'static/sp_testimonial–3/testimonial01_img03.png'
        },
        imageGuest4() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial–3/4B7A9251.png'
                : 'static/sp_testimonial–3/testimonial01_img04.png'
        },

        leadPc() {
            return '40代の女性には<br />絶対にオススメしたいです！'
        },
        leadSp() {
            return '40代の女性には 絶対にオススメしたいです！'
        },
    },
}
</script>

<style lang="scss" scoped>
.question {
    color: #1a0dab;
}
.answer {
    color: #c30013;
}
</style>
