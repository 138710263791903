<template>
    <router-link class="text-decoration-none" :to="{ name: compName }">
        <v-btn text tile class="link-tag my-6 py-6">
            <slot></slot>
        </v-btn>
    </router-link>
</template>

<script>
export default {
    props: {
        compName: {
            type: String,
        },
    },
}
</script>

<style lang="scss" scoped>
.link-tag {
    width: 100%;
    height: 100px;
    text-align: center;
    border: 1px solid;
    border-width: 1px 0;
    border-color: #666666;
}
.link-tag:after {
    font-family: 'Font Awesome 5 Free';
    content: '\f105';
    font-weight: 900;
    top: 50%;
    right: 10px;
    margin-top: -12px;
    position: absolute;
    color: #c30013;
}
</style>
